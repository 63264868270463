import React from "react"
import theme from "theme"
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Revved Up Rentals</title>
        <meta name={"description"} content={"Gear Up for Adventure"} />
        <meta property={"og:title"} content={"Home | Revved Up Rentals"} />
        <meta property={"og:description"} content={"Gear Up for Adventure"} />
        <meta
          property={"og:image"}
          content={"https://amixorestart.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://amixorestart.com/img/scooter.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://amixorestart.com/img/scooter.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://amixorestart.com/img/scooter.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://amixorestart.com/img/scooter.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://amixorestart.com/img/scooter.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://amixorestart.com/img/scooter.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 90px 0"
        background="#e3f6ff"
        quarkly-title="Product-4"
      >
        <Override slot="SectionContent" align-items="center" />
        <Box
          min-width="100px"
          min-height="100px"
          display="block"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 0 0px"
          lg-margin="0px 0px 0 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            align-items="center"
            md-flex-direction="column"
          >
            <Image
              src="https://amixorestart.com/img/1.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 0 0px"
              height="500px"
              width="50%"
              object-fit="cover"
              sm-height="220px"
              md-width="100%"
              md-margin="0px 0px 50px 0px"
              sm-margin="0px 0px 35px 0px"
            />
            <Box
              min-width="100px"
              min-height="100px"
              padding="0px 80px 0px 80px"
              width="50%"
              lg-padding="0px 50px 0px 50px"
              md-width="100%"
              sm-padding="0px 0 0px 0"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 42px/1.2 --fontFamily-sans"
                text-align="left"
                lg-font="normal 600 36px/1.2 --fontFamily-sans"
              >
                Revved Up Rentals
              </Text>
              <Text
                margin="0px 0px 50px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                lg-margin="0px 0px 40px 0px"
                sm-margin="0px 0px 30px 0px"
              >
                Welcome to your next adventure on two wheels! At Revved Up
                Rentals, we believe every journey begins with a great ride.
                Whether you're cruising through city streets or exploring scenic
                backroads, we offer the perfect motorcycle to match your travel
                desires. Our friendly staff ensures a seamless rental experience
                from start to finish.
              </Text>
              <Button
                font="normal 500 18px/1.5 --fontFamily-sans"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
                padding="0 0 0 0"
                background="0"
                color="--primary"
              >
                Get in Touch
              </Button>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          align-items="end"
          grid-gap="0 80px"
          lg-grid-gap="0 50px"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            md-margin="0px 0px 50px 0px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 600 42px/1.2 --fontFamily-sans"
            >
              About Us
            </Text>
            <Text
              margin="0px 0px 70px 0px"
              font="--lead"
              lg-margin="0px 0px 40px 0px"
              color="#626970"
            >
              Revved Up Rentals is your premier destination for high-quality
              motorcycle rentals. With a diverse fleet featuring the latest
              models, we cater to both novice riders and seasoned motorcyclists.
              Our motorcycles are meticulously maintained and inspected to
              ensure your safety and comfort on the road. Choose us for reliable
              service and an unforgettable riding experience.
            </Text>
            <Image
              src="https://amixorestart.com/img/2.jpg"
              display="block"
              width="100%"
              height="600px"
              object-fit="cover"
              border-radius="15px"
              sm-height="400px"
            />
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 500 25px/1.2 --fontFamily-sans"
              >
                Why Choose Us?
              </Text>
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Top-Notch Fleet: Our selection includes everything from sporty
                rides to touring models, all from trusted manufacturers.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Flexible Rental Packages: From hourly rates to weekly
                adventures, we offer a variety of rental options to suit your
                schedule and budget.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Ride with Us – Where the Journey Begins
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 70px 0px"
              lg-margin="0px 0px 40px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Customer-Centric Approach: We prioritize your needs with 24/7
                support and a hassle-free rental process.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
